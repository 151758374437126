import React, { useState, useEffect } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import moment from 'moment';
registerLocale('ja', ja)

const Plan = (props) => {
  const { user } = props;
  const [planCategoryData, setPlanCategoryData] = useState([]);
  const [noticeIntervalMaster, setNoticeIntervalMaster] = useState([]);
  const [planData, setPlanData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const [values, setValues] = useState({ plan_id: null, plan_name: '', plan_category_id: "0", remarks: "", planStart: null, planEnd: null, pdf_url: null, notice_id: "0", notice_type: "0", year_round_type: false, upd_date: null, uploadfile: null, uploadfiletype: null, deletefile: null });

  const refreshPlan = async () => {
    const jwt = localStorage.getItem('jwt');
    var reg_params = {};
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.plan_id - b.plan_id);
    setPlanData(data);

    const plancategory = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/plancategory/`)).data;
    setPlanCategoryData(plancategory);

    const noticeinterval = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/noticeinterval/`)).data;
    setNoticeIntervalMaster(noticeinterval);

    setValues({ plan_id: null, plan_name: '', plan_category_id: (plancategory) ? plancategory[0].plan_category_id : "0", remarks: "", planStart: null, planEnd: null, notice_id: "0", notice_type: "0", year_round_type: false, upd_date: null });
  }

  const handlePlanRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    const plan_start = moment(data.planStart).format("YYYYMMDD");
    const plan_end = moment(data.planEnd).format("YYYYMMDD");

    if (!data.plan_name) {
      window.alert('企画名を入力してください。');
      return;
    }
    if (!data.planStart) {
      window.alert('受付開始日を入力してください。');
      return;
    }
    if (!data.planEnd) {
      window.alert('受付終了日を入力してください。');
      return;
    }

    let res;
    try {
      setLockData(true);
      const form = new FormData();
      if (data.uploadfiletype) form.append("uploadfile", data.uploadfile);
      form.append("plan_id", data.plan_id);
      form.append("plan_category_id", data.plan_category_id);
      form.append("plan_name", data.plan_name);
      form.append("remarks", data.remarks);
      form.append("plan_start", plan_start);
      form.append("plan_end", plan_end);
      form.append("pdf_url", data.pdf_url);
      form.append("notice_id", data.notice_id);
      form.append("notice_type", data.notice_type);
      form.append("year_round_type", data.year_round_type ? '1' : '0');
      form.append("upd_date", data.upd_date);
      form.append("deletefile", data.deletefile);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/`, form, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'content-type': 'multipart/form-data',
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshPlan();
      }
      setLockData(false);
    }
  }
  const handlePlanDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (data.product_count > 0) {
      window.alert('対象の企画に商品が登録されているため、削除できません。');
      return;
    }
    if (!window.confirm(`企画[${data.plan_id}:${data.plan_name}]を削除します。よろしいですか？`)) {
      return;
    }

    const params = {
      plan_id: data.plan_id
      , upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/plan/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshPlan();
      }
      setLockData(false);
    }
  }

  /* 編集ボタン押下 */
  const handlePlanUpdateClick = (data) => {
    const startDate = new Date(data.plan_start.substr(0, 4) + '/' + data.plan_start.substr(4, 2) + '/' + data.plan_start.substr(6, 2));
    const endDate = new Date(data.plan_end.substr(0, 4) + '/' + data.plan_end.substr(4, 2) + '/' + data.plan_end.substr(6, 2));
    const remarks = data.remarks === null ? "" : data.remarks;
    var data2 = {
      plan_id: data.plan_id
      , plan_name: data.plan_name
      , plan_category_id: data.plan_category_id
      , remarks: remarks
      , planStart: startDate
      , planEnd: endDate
      , pdf_url: data.pdf_url
      , notice_id: data.notice_id
      , notice_type: data.notice_type
      , year_round_type: data.year_round_type === '1' ? true : false
      , upd_date: data.upd_date
      , product_count: data.product_count
      , deletefile: null
      , uploadfiletype: null
    };
    if (data.pdf_url != null) data2.uploadfile = constClass.PLAN_IMAGE_DIR + data.pdf_url;
    else data2.uploadfile = null;
    setValues(data2);
  }
  /* RESETボタン押下 */
  const handlePlanResetClick = () => {
    refreshPlan();
  }

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setValues({ ...values, [name]: value });
  }

  const handleChangeDate = (name, value) => {
    setValues({ ...values, [name]: value });
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onload = () => {
      setValues({
        ...values,
        uploadfile: value,
        uploadfiletype: value.type,
        deletefile: values.pdf_url,
        uploadfiledata: URL.createObjectURL(value),
      });
    }
    reader.readAsDataURL(value);
  }

  const handleDeleteFile = () => {
    setValues({ ...values, uploadfile: null, uploadfiletype: null, deletefile: values.pdf_url });
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handlePlanUpdateClick(data)}>
        編集
      </button>
    )
  }
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1`}
        onClick={() => handlePlanRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.plan_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handlePlanDeleteClick(data)}>
        削除
      </button>
    )
  }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1`}
        onClick={() => handlePlanResetClick()}>
        リセット
      </button>
    )
  }

  useEffect(() => {
    refreshPlan();
  }, []);


  return (
    <div className="container">
      <div className="row"><div className="col-12 h5">企画マスタメンテ</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(planData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {planData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {planData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    ID
                  </td>
                  <td className="text-center">
                    企画
                  </td>
                  <td className="text-center">
                    企画カテゴリ
                  </td>
                  <td className="text-center">
                    受付開始日
                  </td>
                  <td className="text-center">
                    受付終了日
                  </td>
                  <td className="text-center">
                    備考
                  </td>
                  <td className="text-center">
                    通年
                  </td>
                  <td className="text-center">
                    通知
                  </td>
                  <td className="text-center">
                    通知内容
                  </td>
                  <td className="text-center">
                    更新日
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {planData.map((data, idx) => (
                  <tr key={data.plan_id}>
                    {/* 企画ID */}
                    <td className="text-center align-middle">
                      {data.plan_id}
                    </td>
                    {/* 企画名 */}
                    <td className="text-center align-middle">
                      {data.plan_name}
                    </td>
                    {/* 企画カテゴリ名 */}
                    <td className="text-center align-middle">
                      {data.plan_category_id}:{data.plan_category_name}
                    </td>
                    {/* 受付開始日 */}
                    <td className="text-center align-middle">
                      {data.plan_start}
                    </td>
                    {/* 受付終了日 */}
                    <td className="text-center align-middle">
                      {data.plan_end}
                    </td>
                    {/* 備考 */}
                    <td className="text-center align-middle">
                      {data.remarks}
                    </td>
                    {/* 通年 */}
                    <td className="text-center align-middle">
                      {data.year_round_type === "1" ? "〇" : "-"}
                    </td>
                    {/* 通知 */}
                    <td className="text-center align-middle">
                      {data.notice_name}<br />
                    </td>
                    {/* 通知 */}
                    <td className="text-center align-middle">
                      {constClass.NOTICE_TYPE_NAME[data.notice_type]}<br />
                    </td>
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderUpdateButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>企画ID</span>
            </div>
            <div className="col-2">
              {values.plan_id !== null && values.plan_id}
              {values.plan_id == null && "新規"}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>企画名</span>
            </div>
            <div className="col-6">
              <input type="text" className="form-control" id="plan_name" name="plan_name" value={values.plan_name} onChange={handleChange} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>企画カテゴリ</span>
            </div>
            <div className="col-6">
              <select className="form-control" id="plan_category_id" name="plan_category_id" value={values.plan_category_id} onChange={handleChange}>
                {planCategoryData.map((data2, idx) => (
                  <option key={data2.plan_category_id} value={data2.plan_category_id} >{data2.plan_category_name}</option>
                ))}</select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>企画PDF</span>
            </div>
            <div className="col-6">
              {!(values.uploadfile) && <input type="file" className="form-control-file" name="pdfUrl" id="pdfUrl" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
              {values.uploadfile && <object id="uploadfileview" data={values.uploadfiledata || values.uploadfile} type={values.uploadfiletype}>File</object>}
              {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>備考</span>
            </div>
            <div className="col-6">
              <textarea className="form-control col-12" id="remarks" name="remarks" onChange={handleChange} value={values.remarks} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>受付期間</span>
            </div>
            <div className="col-2">
              {<DatePicker className="form-control" aria_labal="受付開始日" locale="ja" dateFormat="yyyyMMdd" monthsShown="2" id="planStart" name="planStart" selected={values.planStart} onChange={date => handleChangeDate("planStart", date)} />}
            </div>
            <div className="col-1 w-50 text-center">
              ～
            </div>
            <div className="col-2">
              {<DatePicker className="form-control" aria_labal="受付終了日" locale="ja" dateFormat="yyyyMMdd" monthsShown="2" id="planEnd" name="planEnd" selected={values.planEnd} onChange={date => handleChangeDate("planEnd", date)} />}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>通年設定</span>
            </div>
            <div className="col-1">
              <input type="checkbox" className="form-control" name="year_round_type" id="year_round_type" onChange={handleChange} value="1" checked={values.year_round_type} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>注文通知頻度</span>
            </div>
            <div className="col-3">
              <select className="form-control" id="notice_id" name="notice_id" value={values.notice_id} onChange={handleChange}>
                {noticeIntervalMaster.map((data2, idx) => (
                  <option key={data2.notice_id} value={data2.notice_id} >{data2.notice_name}</option>
                ))}</select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>通知内容区分</span>
            </div>
            <div className="col-3">
              <select className="form-control" id="notice_type" name="notice_type" value={values.notice_type} onChange={handleChange}>
                <option key={constClass.NOTICE_TYPE_NAME.NONE} value={constClass.NOTICE_TYPE.NONE}>{constClass.NOTICE_TYPE_NAME.NONE}</option>
                <option key={constClass.NOTICE_TYPE_NAME.COUNT} value={constClass.NOTICE_TYPE.COUNT}>{constClass.NOTICE_TYPE_NAME.COUNT}</option>
                <option key={constClass.NOTICE_TYPE_NAME.DETAIL} value={constClass.NOTICE_TYPE.DETAIL}>{constClass.NOTICE_TYPE_NAME.DETAIL}</option>
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2 text-center">
              {renderRegistButton(values)}
            </div>
            <div className="col-3 text-center">
              {renderDeleteButton(values)}
            </div>
            <div className="col-3 text-center">
              {renderResetButton(values)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Plan;