import React, { useState, useEffect } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const ProductCategory = (props) => {
  const { user } = props;
  const [productCategoryData, setProductCategoryData] = useState(null);
  const [productCategoryNew, setProductCategoryNew] = useState({product_category_id: null, product_category_name: '', upd_date: null});
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const refreshProductCategory = async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {};
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcategory/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.product_category_id - b.product_category_id);
    setProductCategoryData(data);
    const new_data = {product_category_id: null, product_category_name: '', upd_date: null};
    setProductCategoryNew(new_data);
  }

  const handleProductCategoryRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.product_category_name) {
      window.alert('商品カテゴリ名を入力してください。');
      return;
    }
    const params = {
      product_category_id: data.product_category_id,
      product_category_name: data.product_category_name,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcategory/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshProductCategory();
      }
      setLockData(false);
    }
  }

  const handleProductCategoryDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`商品カテゴリ[${data.product_category_id}:${data.product_category_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      product_category_id: data.product_category_id,
      product_category_name: data.product_category_name,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/productcategory/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshProductCategory();
      }
      setLockData(false);
    }
  }

  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = {product_category_id: null, product_category_name: event.target.value};
      console.log(new_data);
      setProductCategoryNew(new_data);
    } else {
      const _productCategoryData = [...productCategoryData]
      _productCategoryData[index] = { ..._productCategoryData[index], [key]: event.target.value }
      console.log(_productCategoryData);
      setProductCategoryData(_productCategoryData)
    }
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData}
        className={`btn btn-primary mx-1`}
        onClick={() => handleProductCategoryRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.product_category_id === null}
        className={`btn btn-primary mx-1`}
        onClick={() => handleProductCategoryDeleteClick(data)}>
        削除
      </button>
    )
  }

  useEffect(() => {
      refreshProductCategory();
  }, []);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(productCategoryData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {productCategoryData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {productCategoryData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    ID
                  </td>
                  <td className="text-center">
                    商品カテゴリ名
                  </td>
                  <td className="text-center">
                    作成者
                  </td>
                  <td className="text-center">
                    作成日時
                  </td>
                  <td className="text-center">
                    更新者
                  </td>
                  <td className="text-center">
                    更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr key="new">
                  {/* 商品カテゴリID */}
                  <td className="text-center align-middle">
                    新規
                  </td>
                  {/* 商品カテゴリ名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"product_category_name_new"} name="product_category_name[]" value={productCategoryNew.product_category_name} onChange={handleChangeCell('new', 'product_category_name')}></input>
                  </td>
                  {/* 作成者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 作成日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 更新者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(productCategoryNew)}
                    {renderDeleteButton(productCategoryNew)}
                  </td>
                </tr>
                {productCategoryData.map((data, idx) => (
                  <tr key={data.product_category_id}>
                    {/* 商品カテゴリID */}
                    <td className="text-center align-middle">
                      {data.product_category_id}
                    </td>
                    {/* 商品カテゴリ名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" id={"product_category_name" + data.product_category_id} name="product_category_name[]" value={data.product_category_name} onChange={handleChangeCell(idx, 'product_category_name')}></input>
                    </td>
                    {/* 作成者 */}
                    <td className="text-center align-middle">
                      {data.ins_name}
                    </td>
                    {/* 作成日時 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('YYYY-MM-DD HH:mm:ss') : '' }
                    </td>
                    {/* 更新者 */}
                    <td className="text-center align-middle">
                      {data.upd_name}
                    </td>
                    {/* 更新日時 */}
                    <td className="text-center align-middle">
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data)}
                      {renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductCategory;