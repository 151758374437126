import React, { useState, useEffect } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const NoticeInterval = (props) => {
  const { user } = props;
  const [noticeIntervalData, setNoticeIntervalData] = useState(null);
  const [noticeIntervalNew, setNoticeIntervalNew] = useState({notice_id: null, notice_name: '', interval_num: 0, interval_type: 0, upd_date: null});
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();
 
  
  const refreshNoticeInterval = async () => {
    const jwt = localStorage.getItem('jwt');
    const reg_params = {};
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/noticeinterval/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.notice_id - b.notice_id);

    setNoticeIntervalData(data);
    const new_data = {notice_id: null, notice_name: '', interval_num: 0, interval_type: 0, upd_date: null};
    setNoticeIntervalNew(new_data);
  }

  const handleNoticeIntervalRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.notice_name) {
      window.alert('通知頻度名を入力してください。');
      return;
    }
    if (!data.interval_num || data.interval_num<=0) {
      window.alert('通知間隔は1以上の数値を入力してください。');
      return;
    }
    if (!data.interval_type || parseInt(data.interval_type)<=0) {
      window.alert('通知間隔区分を選択してください');
      return;
    }
    const params = {
      notice_id: data.notice_id,
      notice_name: data.notice_name,
      interval_num: data.interval_num,
      interval_type: data.interval_type,
      upd_date: data.upd_date
    };
    console.log(params);
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/noticeinterval/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshNoticeInterval();
      }
      setLockData(false);
    }
  }

  const handleNoticeIntervalDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!window.confirm(`通知頻度[${data.notice_id}:${data.notice_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      notice_id: data.notice_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/noticeinterval/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshNoticeInterval();
      }
      setLockData(false);
    }
  }

  const handleChangeCell = (index, key) => (event) => {
    if (index === 'new') {
      const new_data = {...noticeIntervalNew,[key]: event.target.value}
      setNoticeIntervalNew(new_data);
    } else {
      const _noticeIntervalData = [...noticeIntervalData]
      _noticeIntervalData[index] = { ..._noticeIntervalData[index], [key]: event.target.value }
      setNoticeIntervalData(_noticeIntervalData)
    }
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || constClass.NOTICE_ID_FIXED[data.notice_id]!==undefined}
        className={`btn btn-primary mx-1`}
        onClick={() => handleNoticeIntervalRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.upd_date === null || constClass.NOTICE_ID_FIXED[data.notice_id]!==undefined}
        className={`btn btn-primary mx-1`}
        onClick={() => handleNoticeIntervalDeleteClick(data)}>
        削除
      </button>
    )
  }
  
  useEffect(() => {
      refreshNoticeInterval();
  }, []);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(noticeIntervalData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {noticeIntervalData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {noticeIntervalData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                <tr>
                  <td className="text-center">
                    通知頻度ID
                  </td>
                  <td className="text-center">
                    通知頻度名
                  </td>
                  <td className="text-center w-25">
                    通知間隔
                  </td>
                  <td className="text-center w-25">
                    通知間隔区分
                  </td>
                  <td className="text-center">
                    作成者<br />更新者
                  </td>
                  <td className="text-center">
                    作成日時<br />更新日時
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr key="new">
                  {/* 通知頻度ID */}
                  <td className="text-center align-middle input-number-w120">
                    <input type="text" className="form-control" id={"notice_id_new"} name="notice_id[]" value={noticeIntervalNew.notice_id || ''} onChange={handleChangeCell('new', 'notice_id')}></input>
                  </td>
                  {/* 通知頻度名 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"notice_name_new"} name="notice_name[]" value={noticeIntervalNew.notice_name || ''} onChange={handleChangeCell('new', 'notice_name')}></input>
                  </td>
                  {/* 通知間隔 */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"interval_num_new"} name="interval_num[]" value={noticeIntervalNew.interval_num || ''} onChange={handleChangeCell('new', 'interval_num')}></input>
                  </td>
                  {/* 通知間隔区分 */}
                  <td className="text-center align-middle">
                    <select className="form-control" id={"interval_type_new"} name="interval_type[]" value={noticeIntervalNew.interval_type || ''}　onChange={handleChangeCell('new', 'interval_type')}>
                      <option value="0">指定なし</option>
                      <option value="h">時間毎</option>
                      <option value="d">日毎</option>
                    </select>
                  </td>
                  {/* 作成者更新者 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 作成日時 更新日時 */}
                  <td className="text-center align-middle">
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle">
                    {renderRegistButton(noticeIntervalNew)}
                    {renderDeleteButton(noticeIntervalNew)}
                  </td>
                </tr>
                {noticeIntervalData.map((data, idx) => (
                  <tr key={data.notice_id}>
                    {/* 通知頻度ID */}
                    <td className="text-center align-middle">
                      {data.notice_id}
                    </td>
                    {/* 通知頻度名 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" disabled={constClass.NOTICE_ID_FIXED[data.notice_id]!==undefined} id={"notice_name" + data.notice_id} name="notice_name[]" value={data.notice_name || ''} onChange={handleChangeCell(idx, 'notice_name')}></input>
                    </td>
                    {/* 通知間隔 */}
                    <td className="text-center align-middle">
                      <input type="text" className="form-control" disabled={constClass.NOTICE_ID_FIXED[data.notice_id]!==undefined} id={"interval_num" + data.notice_id} name="interval_num[]" value={data.interval_num || 0} onChange={handleChangeCell(idx, 'interval_num')}></input>
                    </td>
                    {/* 通知間隔区分 */}
                    <td className="text-center align-middle">
                      <select className="form-control" disabled={constClass.NOTICE_ID_FIXED[data.notice_id]!==undefined} id={"interval_type" + data.notice_id} name="interval_type[]" value={data.interval_type || ''}　onChange={handleChangeCell(idx, 'interval_type')}>
                        <option value="0">指定なし</option>
                        <option value="h">時間毎</option>
                        <option value="d">日毎</option>
                      </select>
                    </td>
                    {/* 作成者 更新者*/}
                    <td className="text-center align-middle">
                      {data.ins_name}<br />
                      {data.upd_name}
                    </td>
                    {/* 作成日時 更新日時 */}
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('YYYY-MM-DD HH:mm:ss') : '' }<br />
                      {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </td>
                    {/* 処理 */}
                    <td className="text-center align-middle">
                      {renderRegistButton(data)}
                      {renderDeleteButton(data)}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default NoticeInterval;