import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import { useParams } from 'react-router-dom';

const BackyardOrderInput = (props) => {
  const { id } = useParams();
  const { user } = props;
  const [shopMaster, setShopMaster] = useState([]);
  const [planMaster, setPlanMaster] = useState([]);
  const [productMaster, setProductMaster] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errCheckMsg, setErrCheckMsg] = useState({
    contractor: true,
    customerName: true,
    customerKana: true,
    receiveShop: true,
    customerTel: true,
    planId: true,
    receiveDate: true,
    receiveTime: true,
  })
  const [values, setValues] = useState({
    orderId: "",
    lineId: "PAPER_ORDER",
    status: "",
    receiptNum: "",
    receiptDatetime: "",
    contractor: "",
    inputuser: user.userName,
    customerName: "",
    customerKana: "",
    receiveShop: user.userClass === constClass.CLASS.SHOP ? user.shopId : "",
    customerTel: "",
    planId: "",
    yearRoundType: "0",
    receiveDate: "",
    receiveTime: "",
    subtotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    employeeFlag: "",
    t_order_detail: []
  });

  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? (target.checked ? constClass.EARLY_DISCOUNT.ON : constClass.EARLY_DISCOUNT.OFF) : target.value;
    const name = target.name;
    if (name === "planId") {
      setValues({
        ...values
        , [name]: value
        , yearRoundType: planMaster.find(p => p.plan_id.toString() === value.toString()).year_round_type
        , reciveDate: ""
      });
    } else {
      setValues({ ...values, [name]: value });
    }

    if (name in errCheckMsg) {
      if (value !== "" && value !== null) {
        setErrCheckMsg({ ...errCheckMsg, [name]: true });
      } else {
        setErrCheckMsg({ ...errCheckMsg, [name]: false });
      }
    }

  }
  const handleChangeDate = (name, value) => {
    setValues({ ...values, [name]: (value !== null) ? moment(value).format("YYYY-MM-DD") : "" });
    if (name in errCheckMsg) {
      if (value !== "" && value !== null) {
        setErrCheckMsg({ ...errCheckMsg, [name]: true });
      } else {
        setErrCheckMsg({ ...errCheckMsg, [name]: false });
      }
    }
  }

  const handleOrderDetailChange = (index, key) => (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? (target.checked ? constClass.EARLY_DISCOUNT.ON : constClass.EARLY_DISCOUNT.OFF) : target.value;
    const _productMaster = [...productMaster]
    _productMaster[index] = { ..._productMaster[index], [key]: value }
    setProductMaster(_productMaster);
    // 金額計算
    [values.subtotal, values.discount, values.tax, values.total] = calcAmount(_productMaster);
    setValues(values);
  }

  const calcAmount = (orderDetail) => {
    // 金額計算
    var subtotal = 0; //合計金額
    var discount = 0; //割引金額
    var tax_rates = []; // 税率別合計金額
    var tax = 0; //税額
    var total = 0; //総額
    // 金額計算
    orderDetail.map(async (item) => {
      //合計金額
      var st = item.price_before_tax * item.count;
      subtotal += st;
      //割引金額
      var dc = 0;
      if (item.early_discount === constClass.EARLY_DISCOUNT.ON) {
        dc = Math.ceil(st * item.early_discount_rate);
      }
      discount += dc;
      //税率別合計金額
      const tax_idx = tax_rates.findIndex(t => t.tax_rate === Number(item.tax_rate));
      // 既に税率がある
      if (tax_idx >= 0) {
        tax_rates[tax_idx].total += (st - dc); // 合計金額 - 割引金額
      }
      // 新しい税率
      else {
        tax_rates.push({ tax_rate: Number(item.tax_rate), total: (st - dc) }); // 合計金額 - 割引金額
      }
    });
    //console.log(util.inspect(tax_rates));
    tax = tax_rates.reduce((sum, item) => sum += Math.floor(item.total * item.tax_rate), 0);// 税額計算 sum(合計金額 - 割引金額) * 税率
    total = tax_rates.reduce((sum, item) => sum += Math.floor(item.total * (1 + item.tax_rate)), 0); // 総合計 sum(合計金額 - 割引金額) * (1 + 税率)
    return [subtotal, discount, tax, total];
  }

  const getMinDateReceiveDate = (receive_date, productMaster) => {
    const date1 = (!receive_date || receive_date === '') ? null : moment(receive_date.replace(/-/g, '/'));
    const date2 = moment().add(productMaster.length > 0 ? parseInt(productMaster.sort((a, b) => parseInt(a.receipt_limit_days) - parseInt(b.receipt_limit_days))[0].receipt_limit_days) : 0, 'days');
    if (date1 !== null && date2.isAfter(date1)) return new Date(date1.format('YYYY/MM/DD'));
    return new Date(date2.format('YYYY/MM/DD'));
  }

  const history = useHistory();
  const { addToast } = useToasts();

  const refreshProduct = useCallback(async () => {
    var reg_params = {};
    reg_params = {
      "operator": "and",
      "where": [{ plan_id: values.planId }]
    }
    if (values.planId !== null && values.planId !== "") {
      const product = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, reg_params)).data;
      product.forEach((p, idx) => {
        //console.log(values);
        const orderDetail = values.t_order_detail.find(d => d.product_id === p.product_id);
        //console.log(orderDetail);
        p.count = orderDetail && orderDetail.count ? orderDetail.count : 0;
        p.early_discount = orderDetail && orderDetail.early_discount ? orderDetail.early_discount : constClass.EARLY_DISCOUNT.OFF;
      });
      setProductMaster(product);
    } else {
      setProductMaster([]);
    }
  }, [values.planId, values.t_order_detail]);

  const refreshOrder = useCallback(async () => {
    const shop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/`)).data;
    setShopMaster(shop);
    const plan = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/plan/`)).data;
    setPlanMaster(plan);
    if (id) {
      const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/${id}`)).data;
      //data.sort((a, b) => a.order_id - b.order_id);
      //console.log(data);
      const temp = {
        orderId: data.order_id,
        lineId: data.line_id,
        status: data.status,
        receiptNum: data.receipt_num || "",
        receiptDatetime: data.receipt_datetime || "",
        contractor: data.contractor || "",
        inputuser: data.inputuser || user.userName,
        customerName: data.customer_name || "",
        customerKana: data.customer_kana || "",
        receiveShop: data.receive_shop || "",
        customerTel: data.customer_tel || "",
        planId: data.plan_id || "",
        receiveDate: data.receive_date || "",
        receiveTime: data.receive_time || "",
        subtotal: Math.floor(data.subtotal) || 0,
        discount: Math.floor(data.discount) || 0,
        tax: Math.floor(data.tax) || 0,
        total: Math.floor(data.total) || 0,
        employeeFlag: data.employee_flag || "",
        t_order_detail: data.t_order_details || [],
        yearRoundType: plan.find(p => p.plan_id.toString() === data.plan_id.toString()).year_round_type
      };
      setValues(temp);

    } else {
      const temp = {
        orderId: "",
        lineId: "PAPER_ORDER",
        status: "",
        receiptNum: "",
        receiptDatetime: "",
        contractor: "",
        inputuser: user.userName,
        customerName: "",
        customerKana: "",
        receiveShop: user.userClass === constClass.CLASS.SHOP ? user.shopId : "",
        customerTel: "",
        planId: plan.length === 1 ? plan[0].plan_id : "",
        receiveDate: "",
        receiveTime: "",
        subtotal: 0,
        discount: 0,
        tax: 0,
        total: 0,
        employeeFlag: "",
        t_order_detail: [],
        yearRoundType: "0"
      };
      setValues(temp);
      const chk = {
        contractor: false,
        customerName: false,
        customerKana: false,
        receiveShop: user.userClass === constClass.CLASS.SHOP ? true : false,
        customerTel: false,
        planId: plan.length === 1 ? true : false,
        receiveDate: false,
        receiveTime: false,
      }
      setErrCheckMsg(chk);
      // if (plan.length === 1) refreshProduct();
    }
    //console.log(values);
  }, [id, user]);

  const checkReceiveDate = (product, receiveDate, receiptDatetime = null) => {
    if (!receiveDate || receiveDate === "") return false;
    if (planMaster.find(p => p.plan_id.toString() === product.plan_id.toString()).year_round_type === '1') {
      // 受取可能日チェック　受取可能日数未満の日付が指定されている
      const date1 = moment().add(product.receipt_limit_days, 'days');//受取可能開始日
      const date2 = moment(receiveDate, "YYYY-MM-DD");//受取日

      if (date1.format('YYYYMMDD') > date2.format('YYYYMMDD')) {
        if (receiptDatetime !== null) {//注文済みの場合は初回注文日を基準として日付チェック
          const date3 = moment(receiptDatetime).add(product.receipt_limit_days, 'days');//受取可能開始日
          if (date3.format('YYYYMMDD') > date2.format('YYYYMMDD')) return false;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      if (product.m_product_receive.filter(p => p.receive_date === receiveDate.replace(/-/g, '')).length <= 0) { //m_product_receiveに含まれない
        return false;
      }
    }
    return true
  }

  const handleOrderRegistClick = async (order, orderDetail) => {

    var shop_error = [];
    var date_error = [];
    var change_error = [];
    // エラーチェック
    orderDetail.filter(d => d.count > 0).forEach((detail) => {
      var product = productMaster.find(p => p.product_id === detail.product_id);
      // 受取不可店舗チェック
      if (product.m_ng_shop.filter(p => p.shop_id === order.receiveShop).length > 0) { //m_ng_shopに含まれる
        shop_error.push(detail.product_id);
      }
      // 受取可能日チェック
      if (!checkReceiveDate(product, order.receiveDate)) date_error.push(detail.product_id);

      //変更可能日チェック　
      if (planMaster.find(p => p.plan_id.toString() === product.plan_id.toString()).year_round_type === '1') {
        //変更可能日以降の日付が指定されている
        if (order.receiveDate) {
          const date3 = moment().format('YYYYMMDD');//今日
          const date4 = moment(order.receiveDate).subtract(product.change_limit_days, 'days').format('YYYYMMDD');//受取日 - 変更可能日数
          if (date3 > date4) date_error.push(detail.product_id);
        }
      } else {
        // 変更可能日チェック
        if (order.orderId && product.change_limit < moment().add(-1, 'days').format('YYYYMMDD')) { //変更可能日の翌日を過ぎている
          change_error.push(detail.product_id);
        }
      }
      // 締め切り日チェック
      if (!order.orderId && product.order_limit < moment().add(-1, 'days').format('YYYYMMDD')) { //締め切り日の翌日を過ぎている
        change_error.push(detail.product_id);
      }
    });
    // 受取日・店舗チェック
    var msg = '';
    if (shop_error.length > 0) {
      msg += 'この店舗では受け取れない商品が含まれています。\n';
    }
    if (date_error.length > 0) {
      msg += '指定された受取日に受け取れない商品が含まれています。\n';
    }
    if (msg) {
      // 権限によって処理を変更
      if (user.userClass === constClass.CLASS.ADMIN) {
        if (!window.confirm(msg + 'お客様へ電話でご確認をお願いします。\n本当に登録しますか？')) {
          return;
        }
      } else {
        window.alert(msg + 'お客様へ電話でご確認をお願いします。');
        return;
      }
    }
    // 変更可能日・締め切り日チェック
    msg = '';
    if (change_error.length > 0) {
      if (order.orderId) {
        msg += '変更可能日を過ぎています。\n';
      } else {
        msg += '締め切り日を過ぎています。\n';
      }
    }
    if (msg) {
      // 権限によって処理を変更
      if (user.userClass === constClass.CLASS.ADMIN) {
        if (!window.confirm(msg + '本当に登録しますか？')) {
          return;
        }
      } else {
        window.alert(msg + '本部へご連絡をお願いします。');
        return;
      }
    }

    // orderデータ準備
    var data = {
      'line_id': order.lineId,
      'status': order.status,
      'customer_name': order.customerName,
      'customer_kana': order.customerKana,
      'receive_shop': order.receiveShop,
      'customer_tel': order.customerTel,
      'plan_id': order.planId,
      'receive_date': order.receiveDate,
      'receive_time': order.receiveTime ? order.receiveTime : null,
      // 'contractor': order.orderId ? order.contractor : user.userShort,  // 更新のときはそのまま、新規のときは担当者名
      'contractor': order.contractor,
      'inputuser': order.inputuser,
      'employee_flag': order.employeeFlag,
      't_order_detail': [],
    };
    // order_detailデータ準備
    orderDetail.forEach((detail) => {
      if (Number(detail.count) > 0) {
        data.t_order_detail.push({
          'product_id': detail.product_id,
          'early_discount': detail.early_discount,
          'count': detail.count,
        });
      }
    });

    // API実行
    try {
      setButtonDisabled(true);
      var result;
      if (orderDetail.length > 0) {
        if (order.orderId) {
          result = (await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/backyard/${order.orderId}`, data)).data;
        } else {
          result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/`, data)).data;
        }
      }
      if (result.result) {
        //console.log(result.order);
        values.orderId = result.order.order_id;
        values.lineId = result.order.line_id;
        values.status = result.order.status;
        values.receiptNum = result.order.receipt_num;
        values.receiptDatetime = result.order.receipt_datetime;
        values.contractor = result.order.contractor;
        values.inputuser = result.order.inputuser;
        values.subtotal = result.order.subtotal;
        values.discount = result.order.discount;
        values.tax = result.order.tax;
        values.total = result.order.total;
        values.employeeFlag = result.order.employee_flag;
        setValues(values);
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
      } else {
        //errors.push('エラーが発生しました。もう一度お試しください。');
        addToast('エラーが発生しました。もう一度お試しください。', { appearance: 'error', autoDismiss: true });
      }
    } catch (err) {
      //errors.push(err);
      console.log(util.inspect(err));
      addToast(err, { appearance: 'error', autoDismiss: true });
    } finally {
      if (id) {
        history.replace(`${props.match.path}`);
      } else {
        refreshOrder();
        setButtonDisabled(false);
      }
    }
  }

  const renderRegistButton = () => {
    const disabled = buttonDisabled || values.status === constClass.STATUS.CCL || values.status === constClass.STATUS.FIN
      || !values.customerName || !values.customerKana || !values.customerTel || !values.receiveShop || !values.planId || !values.receiveDate || !values.receiveTime
      || !(productMaster.filter(p => p.count > 0).length > 0);
    return (
      <button type="button"
        disabled={disabled}
        className={`btn btn-primary mx-1 my-2 px-5`}
        onClick={() => handleOrderRegistClick(values, productMaster)}>
        登録
      </button>
    )
  }

  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  useEffect(() => {
    refreshProduct();
  }, [values.planId, refreshProduct]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      <div className="row pt-1 pb-2">
        <div className="col-1 text-center align-self-center">
          <span>注文ID</span>
        </div>
        <div className="col-2 text-center align-self-center">
          <input type="text" className="form-control" name="orderId" value={values.orderId} readOnly></input>
        </div>
        <div className="col-1 text-center align-self-center">
          <span>LINE ID</span>
        </div>
        <div className="col-2 text-center align-self-center">
          <input type="text" className="form-control" name="lineId" value={values.lineId} readOnly></input>
        </div>
        {/*<div className="col-1 text-center">
          <span>受付番号</span>
        </div>
        <div className="col-2 text-center">
          <input type="text" className="form-control" name="receiptNum" value={values.receiptNum} readOnly></input>
        </div>*/}
        <div className="col-1 text-center align-self-center">
          <span>受付日時</span>
        </div>
        <div className="col-2 text-center align-self-center">
          <input type="text" className="form-control" name="receiptDatetime" value={values.receiptDatetime ? moment(values.receiptDatetime).format('YYYY-MM-DD HH:mm:ss') : values.receiptDatetime} readOnly></input>
        </div>
        <div className="col-1 text-center text-nowrap align-self-center">
          <span>ステータス</span>
        </div>
        <div className="col-2 text-center align-self-center">
          <input type="text" className="form-control" name="status" value={values.status ? constClass.STATUS_NAME[values.status] : ""} readOnly></input>
        </div>
      </div>
      <div className="row pt-1 pb-2">
        <div className="col-1 text-center align-self-center">
          <span>受注者</span>
        </div>
        <div className="col-3 text-center align-self-center">
          <input type="text" className={`form-control ${!errCheckMsg.contractor ? 'is-invalid' : ''}`} id="contractor" name="contractor" value={values.contractor} onChange={handleChange} required></input>
        </div>
        <div className="col-1 text-center text-nowrap align-self-center">
          <span>入力者</span>
        </div>
        <div className="col-2 text-center align-self-center">
          <input type="text" className="form-control" name="inputuser" value={values.inputuser} readOnly></input>
        </div>
      </div>
      <div className="row pt-1 pb-2">
        <div className="col-1 text-center align-self-center">
          <span>企画</span>
        </div>
        <div className="col-3 text-center align-self-center">
          <select className={`custom-select w-100 ${!errCheckMsg.planId ? 'is-invalid' : ''}`} aria-label="企画" name="planId" value={values.planId} onChange={handleChange} required>
            <option value="">- 選択 -</option>
            {planMaster.map((item) =>
              <option key={item.plan_id} value={item.plan_id}>{item.plan_name}</option>
            )}
          </select>
        </div>
        <div className="col-1 text-center align-self-center">
          <span>受取店舗</span>
        </div>
        <div className="col-7 text-center align-self-center">
          <select className={`custom-select w-100 ${!errCheckMsg.receiveShop ? 'is-invalid' : ''}`} aria-label="店舗" name="receiveShop" value={values.receiveShop} onChange={handleChange} required>
            <option value="">- 選択 -</option>
            {shopMaster.filter(s => s.shop_id > 0).map((item) => // 受取店舗 本社を除く
              <option key={item.shop_id} value={item.shop_id}>{item.shop_name}</option>
            )}
          </select>
        </div>
      </div>
      <div className="row pt-1 pb-2">
        <div className="col-1 text-center align-self-center">
          <span>お客様名</span>
        </div>
        <div className="col-3 text-center align-self-center">
          <input type="text" className={`form-control ${!errCheckMsg.customerName ? 'is-invalid' : ''}`} name="customerName" value={values.customerName} onChange={handleChange} required></input>
        </div>
        <div className="col-1 text-center align-self-center">
          <span>お客様名(カナ)</span>
        </div>
        <div className="col-3 text-center align-self-center">
          <input type="text" className={`form-control ${!errCheckMsg.customerKana ? 'is-invalid' : ''}`} name="customerKana" value={values.customerKana} onChange={handleChange} required></input>
        </div>
        <div className="col-1 text-center align-self-center">
          <span>電話番号</span>
        </div>
        <div className="col-3 text-center align-self-center">
          <input type="text" className={`form-control ${!errCheckMsg.customerTel ? 'is-invalid' : ''}`} name="customerTel" value={values.customerTel} onChange={handleChange} required></input>
        </div>
      </div>
      <div className="row pt-1 pb-2">
        <div className="col-1 text-center align-self-center">
          <span>受取日</span>
        </div>
        <div className="col-3 text-center align-self-center">
          {values.yearRoundType === '1' &&
            <DatePicker className={`form-control ${!errCheckMsg.receiveDate ? 'is-invalid' : ''}`} aria_labal="受取日" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="receiveDate" name="receiveDate"
              selected={(!values.receiveDate || values.receiveDate === '') ? null : new Date(values.receiveDate.replace(/-/g, '/'))}
              minDate={getMinDateReceiveDate(values.receiveDate, productMaster)}
              onChange={date => handleChangeDate("receiveDate", date)}
            />
          }
          {(values.yearRoundType !== '1') &&
            <select className={`custom-select w-100 text-center ${!errCheckMsg.receiveDate ? 'is-invalid' : ''}`} aria-label="受取日" value={values.receiveDate} id="receiveDate" name="receiveDate" onChange={handleChange} required>
              <option value="">- 選択 -</option>
              {productMaster
                .reduce((arr, item) => arr.concat(item.m_product_receive), []) //m_product_receiveの配列作成
                .map(item => item.receive_date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => parseInt(a) - parseInt(b)).map(item => // receive_dateの配列に変換→重複除外→オプションのDOM要素に変換
                  <option key={item} value={item.substr(0, 4) + '-' + item.substr(4, 2) + '-' + item.substr(6, 2)}>{item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2)}</option>
                )}
            </select>
          }
        </div>
        <div className="col-1 text-center align-self-center">
          <span>時間帯</span>
        </div>
        <div className="col-2 text-center align-self-center">
          <select className={`custom-select w-100 ${!errCheckMsg.receiveTime ? 'is-invalid' : ''}`} aria-label="時間帯" name="receiveTime" value={values.receiveTime} onChange={handleChange} required>
            <option value="">- 選択 -</option>
            <option value={constClass.RECEIVE_TIME.NONE}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.NONE]}</option>
            <option value={constClass.RECEIVE_TIME.AM9}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.AM9]}</option>
            <option value={constClass.RECEIVE_TIME.AM11}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.AM11]}</option>
            <option value={constClass.RECEIVE_TIME.PM1}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM1]}</option>
            <option value={constClass.RECEIVE_TIME.PM3}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM3]}</option>
            <option value={constClass.RECEIVE_TIME.PM5}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM5]}</option>
            <option value={constClass.RECEIVE_TIME.PM7}>{constClass.RECEIVE_TIME_NAME[constClass.RECEIVE_TIME.PM7]}</option>
          </select>
        </div>
        <div className="col-3 text-center align-self-center">
        </div>
        <div className="col-1 text-center align-self-center">
          <span>従業員</span>
        </div>
        <div className="col-1 text-center align-self-center">
          <input type="checkbox" className={`form-control`} name="employeeFlag" checked={values.employeeFlag === constClass.EARLY_DISCOUNT.ON} onChange={handleChange}></input>
        </div>
      </div>
      <div className="row pt-1 pb-2">
        <div className="col-1 text-center"></div>
        <div className="col-11">
          <table className="table table-bordered table-striped">
            <thead className={`table-${constClass.COLOR.BUS}`}>
              <tr>
                <th className="text-center w-30">
                  商品番号
                </th>
                <th className="text-center w-30">
                  商品名
                </th>
                <th className="text-center">
                  税込単価
                </th>
                <th className="text-center">
                  税別単価
                </th>
                <th className="text-center w-10">
                  注文数量
                </th>
                <th className="text-center">
                  早割対象
                </th>
              </tr>
            </thead>
            {productMaster.length === 0 && (
              <tbody>
                <tr><td colSpan="6">企画を選択してください。</td></tr>
              </tbody>
            )}
            {productMaster.length > 0 && (
              <tbody>
                {productMaster.sort((a, b) => a.product_code.length === b.product_code.length ? (a.product_code < b.product_code ? -1 : 1) : a.product_code.length - b.product_code.length).map((data, idx) => (
                  <tr key={data.product_id}>
                    <td className="text-center">
                      {data.product_code}
                    </td>
                    <td>
                      {data.product_name}
                      {(values.receiveShop && data.m_ng_shop.filter(p => p.shop_id.toString() === values.receiveShop.toString()).length > 0) && (
                        <div className="small text-danger">指定された店舗では受け取れません</div>
                      )}
                      {(values.receiveDate && !checkReceiveDate(data, values.receiveDate, values.receiptDatetime)) &&
                        <div className="small text-danger">指定された受取日に受け取れません</div>
                      }
                    </td>
                    <td className="text-right">
                      {data.price.toLocaleString()}
                    </td>
                    <td className="text-right">
                      {Math.floor(data.price_before_tax).toLocaleString()}
                    </td>
                    <td className="pt-1 pb-1 text-center input-number-w120">
                      <input type="number" className={`form-control text-right ${data.count <= 0 || ((!values.receiveShop || data.m_ng_shop.filter(p => p.shop_id === values.receiveShop).length <= 0)
                        && (!values.receiveDate || !checkReceiveDate(data, values.reciveDate))) ? '' : 'is-invalid'}`} min="0" step="1" name={'count[]'} value={data.count} onChange={handleOrderDetailChange(idx, 'count')}
                      ></input>
                    </td>
                    <td className="pt-1 pb-1 text-center">
                      <input type="checkbox" className="form-control" name={'early_discount[]'} checked={data.early_discount === constClass.EARLY_DISCOUNT.ON} onChange={handleOrderDetailChange(idx, 'early_discount')}></input>
                    </td>
                  </tr>
                ))
                }
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="row pt-1 pb-2">
        <div className="col-1 text-center">
          <span>合計金額</span>
        </div>
        <div className="col-2 text-center">
          <input type="text" className="form-control text-right" name="subtotal" value={values.subtotal.toLocaleString()} readOnly></input>
        </div>
        <div className="col-1 text-center">
          <span>割引金額</span>
        </div>
        <div className="col-2 text-center">
          <input type="text" className="form-control text-right" name="discount" value={values.discount.toLocaleString()} readOnly></input>
        </div>
        <div className="col-1 text-center">
          <span>税額</span>
        </div>
        <div className="col-2 text-center">
          <input type="text" className="form-control text-right" name="tax" value={values.tax.toLocaleString()} readOnly></input>
        </div>
        <div className="col-1 text-center">
          <span>総額</span>
        </div>
        <div className="col-2 text-center">
          <input type="text" className="form-control text-right" name="total" value={values.total.toLocaleString()} readOnly></input>
        </div>
      </div>
      <div className="row">
        <div className="col-8 text-left">
          <span className="text-danger">
            {values.status === constClass.STATUS.CCL ? "※この注文データはキャンセル済みのため更新できません。" : ""}
            {values.status === constClass.STATUS.FIN ? "※この注文データは受取済のため更新できません。" : ""}
          </span>
        </div>
        <div className="col-4 text-right">
          {renderRegistButton()}
        </div>
      </div>
    </div>
  )
}

export default BackyardOrderInput;