import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import BackyardOrderShop from './BackyardOrderShop'
import BackyardOrderList from './BackyardOrderList';
import BackyardOrderProduct from './BackyardOrderProduct';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import Select from 'react-select';
import moment from 'moment';

const BackyardOrderFinTab = (props) => {
  const { user } = props;
  registerLocale('ja', ja);

  const [index, setIndex] = useState(1);
  const handleTabSelect = (idx, lastIndex, e) => {
    setIndex(idx);
    if (user.userClass === constClass.CLASS.SHOP) { //店舗ユーザは自店舗を設定
      setShop(user.shopId);
    }
  };

  const [shopMaster, setShopMaster] = useState([]);
  const [planMaster, setPlanMaster] = useState([]);
  const [productMaster, setProductMaster] = useState([]);
  const [shop, setShop] = useState('');
  const [plan, setPlan] = useState('0');
  const [reciveDate, setReciveDate] = useState(null);
  const [selectReciveDate, setSelectReciveDate] = useState(null);
  const [selectStatus, setSelectStatus] = useState([{ value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG },
  { value: constClass.STATUS.PRE, label: constClass.STATUS_NAME.PRE },
  { value: constClass.STATUS.FIN, label: constClass.STATUS_NAME.FIN }]);
  const [status, setStatus] = useState([constClass.STATUS.REG, constClass.STATUS.PRE, constClass.STATUS.FIN]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isReload, setisReload] = useState(true);

  const handleChange = (event) => {
    if (event.target.name === 'shop') {
      setShop(event.target.value);
    } else if (event.target.name === 'plan') {
      setPlan(event.target.value);
    } else if (event.target.name === 'startdate') {
      setStartDate(event.target.value);
      if (endDate === null) setEndDate(event.target.value);
    } else if (event.target.name === 'enddate') {
      setEndDate(event.target.value);
      if (startDate === null) setStartDate(event.target.value);
    }
  }

  const handleSelectReciveDate = (data) => {
    if (data.length <= 0) {
      setSelectReciveDate(null);
      setReciveDate(null);
    } else {
      setSelectReciveDate(data);
      setReciveDate(data.map((item) => item.value));
    }
  }
  const handleSelectStatus = (data) => {
    if (data.length <= 0) {
      setSelectStatus(null);
      setStatus(null);
    } else {
      setSelectStatus(data);
      setStatus(data.map((item) => item.value));
    }
  }
  const handleIsReload = (data) => {
    setisReload(data);
  }

  useEffect(() => {
    async function fetchData() {
      const shop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/`)).data;
      setShopMaster(shop);
      const plan = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/plan/`)).data;
      setPlanMaster(plan);
      if(plan.length > 0){//企画が複数あったら、開催中企画を表示。なければ最新企画を表示
        const nowdate=moment().format("YYYYMMDD");
        const nowplan=plan.find((item)=> item.plan_start<=nowdate && item.plan_end>=nowdate);
        const defaultplan=nowplan?nowplan.plan_id:plan.slice(-1)[0].plan_id;
        setPlan(defaultplan);
      }
      const product = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/`)).data;
      setProductMaster(product);
      if (user.userClass === constClass.CLASS.SHOP) { //店舗ユーザは自店舗を初期値に設定
        setShop(user.shopId);
      }
    }
    fetchData();
  }, [user.shopId, user.userClass]);

  return (
    <div className="container">
      <div className="row mx-0 my-3 px-1-env">
        <div className="col">
          <div className="row">
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>店舗</span>
                </div>
                <div className="col text-center">
                  <select className="custom-select w-100" aria-label="店舗" name="shop" value={shop} onChange={handleChange} disabled={(index === 1 && user.userClass === constClass.CLASS.SHOP) ? true : false}>
                    <option value="">- 全て -</option>
                    {shopMaster.map((item) =>
                      <option key={item.shop_id} value={item.shop_id}>{item.shop_name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>企画</span>
                </div>
                <div className="col text-center">
                  <select className="custom-select w-100" aria-label="企画" name="plan" value={plan} onChange={handleChange}>
                    <option value="">- 全て -</option>
                    {planMaster.map((item) =>
                      <option key={item.plan_id} value={item.plan_id}>{item.plan_name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-auto text-center align-self-center">
                  <span>受取日</span>
                </div>
                <div className="col text-center">
                  {/*<select className="custom-select w-100" aria-label="受取日" name="startdate" value={startDate} onChange={handleChange}>
                    <option value="">- 全て -</option>
                      {productMaster.filter(p => (p.plan_id = plan || !plan)) //productMasterをcount有りでフィルタ
                      .reduce((arr, item) => arr.concat(item.m_product_receive), []) //m_product_receiveの配列作成
                      .map(item => item.receive_date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => parseInt(a) - parseInt(b)).map(item => // receive_dateの配列に変換→重複除外→オプションのDOM要素に変換
                        <option value={item}>{item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2)}</option>
                      )}
                      </select>*/}
                  <Select
                    styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                    options={productMaster.filter(p => (p.plan_id === Number(plan) || !plan)) //productMasterをcount有りでフィルタ
                      .reduce((arr, item) => arr.concat(item.m_product_receive), []) //m_product_receiveの配列作成
                      .map(item => item.receive_date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => parseInt(a) - parseInt(b)).map( // receive_dateの配列に変換→重複除外→オプションのDOM要素に変換
                        item => ({ value: item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2), label: item.substr(0, 4) + '/' + item.substr(4, 2) + '/' + item.substr(6, 2) })
                      )}
                    isClearable={false}
                    isMulti
                    closeMenuOnSelect={false}
                    value={selectReciveDate}
                    onChange={handleSelectReciveDate} />
                  {/*{<DatePicker className="form-control" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="2" selected={startDate} onChange={handleStartDateChange}/>}*/}
                </div>
              </div>
            </div>
            {index === 0 &&
              <div className="col-2"></div>
            }
            {index === 1 &&
              <div className="col-2">
                <div className="row">
                  {/* <div className="col-auto text-center align-self-center">
                <span>ステータス</span>
              </div> */}
                  <div className="col text-center">
                    <Select
                      styles={{ container: (base) => ({ ...base, zIndex: 500 }) }}
                      options={[{ value: constClass.STATUS.REG, label: constClass.STATUS_NAME.REG },
                      { value: constClass.STATUS.PRE, label: constClass.STATUS_NAME.PRE },
                      { value: constClass.STATUS.FIN, label: constClass.STATUS_NAME.FIN },
                      { value: constClass.STATUS.CCL, label: constClass.STATUS_NAME.CCL },]}
                      isClearable={false}
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectStatus}
                      placeholder={'ステータス...'}
                      backspaceRemovesValue={false}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                      onChange={handleSelectStatus} />
                  </div>
                </div>
              </div>
            }
            <div className="col-1 text-center">
              <button type="button"
                className={`btn btn-primary mx-1 w-100`}
                onClick={() => handleIsReload(!isReload)}>
                検索</button>
            </div>
            {/*<div className="col-1 text-center">
              <span>～</span>
            </div>
            <div className="col-2 text-center">
              {<DatePicker className="form-control" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="2" selected={endDate} onChange={handleEndDateChange}/>}
            </div>*/}
          </div>
        </div>
      </div>

      <Tabs onSelect={handleTabSelect} defaultIndex={index}>
        <TabList className="nav nav-pills mb-2">
          <Tab className="nav-item"><div className={`nav-link btn ${index === 0 ? 'active' : ''}`} href="#">店舗別企画商品別</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${index === 1 ? 'active' : ''}`} href="#">注文一覧</div></Tab>
          <Tab className="nav-item"><div className={`nav-link btn ${index === 2 ? 'active' : ''}`} href="#">注文一覧(商品別)</div></Tab>
        </TabList>
        <TabPanel>
          <BackyardOrderShop {...props} {...{ condition: { shop, plan, reciveDate, status, isReload } }} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderList {...props} {...{ condition: { user, shop, plan, reciveDate, status, isReload } }} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderProduct {...props} {...{ condition: { user, shop, plan, reciveDate, status, isReload } }} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default BackyardOrderFinTab;