import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
import moment from 'moment';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ProductList from '../Order/ProductList';
registerLocale('ja', ja)

const Product = (props) => {
  const { user } = props;
  const [plan, setPlan] = useState({ plan_id: null, product_category_id: null, });
  const [limitDate, setLimitDate] = useState({ min: null, max: null });
  const [shopMaster, setShopMaster] = useState([]);
  const [planMaster, setPlanMaster] = useState([]);
  const [productCategoryMaster, setProductCategoryMaster] = useState([]);
  const [productDeptMaster, setProductDeptMaster] = useState([]);
  const [productData, setProductData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast } = useToasts();

  const [values, setValues] = useState({
    plan_id: null,
    year_round_type: "0",
    product_id: null,
    product_code: '',
    product_name: '',
    product_category_id: "0",
    product_dept_code: constClass.PRODUCT_DEPT_DEFAULT,
    product_detail: '',
    product_image: null,
    price_before_tax: 0,
    price: 0,
    tax_rate: 0,
    order_limit: null,
    change_limit: null,
    point: 0,
    point_rate: 0,
    early_discount_rate: 0,
    early_discount_start: null,
    early_discount_end: null,
    stock: 0,
    upd_date: null,
    orderLimit: null,
    changeLimit: null,
    earlyDiscountStart: null,
    earlyDiscountEnd: null,
    uploadfile: null,
    uploadfiletype: null,
    deletefile: null
  });
  const [ngShop, setNgshopValues] = useState([]);
  const [receiveDate, setReceiveDateValues] = useState([]);

  const refreshProduct = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');

    const plandata = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/plan/`)).data;
    setPlanMaster(plandata);

    const shop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/`)).data.map((val) => { return { value: val.shop_id, label: val.shop_name }; });
    setShopMaster(shop);

    const productCategory = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/productcategory/`)).data;
    setProductCategoryMaster(productCategory);

    const productdept = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/productdept/`)).data;
    setProductDeptMaster(productdept);

    var reg_params = {};
    if (plan.plan_id === null && plandata.length > 0) {//企画が複数あったら、開催中企画を表示。なければ最新企画を表示
      const nowdate = moment().format("YYYYMMDD");
      const nowplan = plandata.find((item) => item.plan_start <= nowdate && item.plan_end >= nowdate);
      const defaultplan = nowplan ? nowplan.plan_id : plandata.slice(-1)[0].plan_id;
      setPlan({ ...plan, plan_id: defaultplan });
    }
    if ((plan.plan_id !== null && plan.plan_id !== '') || (plan.product_category_id !== null && plan.product_category_id !== '')) {
      reg_params = {
        "operator": "and",
        "where": []
      }
      if (plan.plan_id !== null && plan.plan_id !== '') reg_params.where.push({ "plan_id": plan.plan_id });
      if (plan.product_category_id !== null && plan.product_category_id !== '') reg_params.where.push({ "product_category_id": plan.product_category_id });

    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/search/`, reg_params, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    data.sort((a, b) => a.product_id - b.product_id);
    setProductData(data);

    setNgshopValues([]);
    setReceiveDateValues([]);
    setLimitDate({ min: null, max: null });

  }, [plan]);

  const strToDate = (date) => {
    if (date === '') return null;
    return new Date(date.substr(0, 4) + '/' + date.substr(4, 2) + '/' + date.substr(6, 2));
  }
  const dateToStore = (date) => {
    if (date === null) return "";
    return moment(date).format("YYYYMMDD");;
  }
  /* 編集ボタン押下 */
  const handleProductUpdateClick = (data) => {
    var data2 = data;
    data2.orderLimit = strToDate(data.order_limit);
    data2.changeLimit = strToDate(data.change_limit);
    data2.earlyDiscountStart = strToDate(data.early_discount_start);
    data2.earlyDiscountEnd = strToDate(data.early_discount_end);
    data2.deletefile = null;
    data2.uploadfiletype = null;
    if (data.product_image != null) data2.uploadfile = constClass.PRODUCT_IMAGE_DIR + data.product_image;
    else data2.uploadfile = null;
    setValues(data2);
    
    var data3 = data.m_product_receive.map((a) => {
      return { value: a.receive_date, label: a.receive_date };
    });
    setReceiveDateValues(data3);

    var data4 = data.m_ng_shop.map((a) => {
      return { value: a.shop_id, label: a.m_shop.shop_name };
    });
    setNgshopValues(data4);

    const data5 = planMaster.find(p => p.plan_id.toString() === data.plan_id.toString());
    if (data5){
      setLimitDate({ ...limitDate, min: strToDate(data5.plan_start), max: strToDate(data5.plan_end) });
    } 
  }
  /* RESETボタン押下 */
  const handleProductResetClick = () => {
    setValues({
      plan_id: '',
      year_round_type: "0",
      product_id: null,
      product_code: '',
      product_name: '',
      product_category_id: "0",
      product_dept_code: constClass.PRODUCT_DEPT_DEFAULT,
      product_detail: '',
      product_image: null,
      price_before_tax: 0,
      price: 0,
      tax_rate: 0.08,
      order_limit: null,
      change_limit: null,
      point: 0,
      point_rate: 0,
      early_discount_rate: 0,
      early_discount_start: null,
      early_discount_end: null,
      stock: 0,
      upd_date: null,

      m_product_receive: [],
      m_ng_shop: [],

      orderLimit: null,
      changeLimit: null,
      earlyDiscountStart: null,
      earlyDiscountEnd: null,
      uploadfile: null,
      uploadfiletype: null,
      deletefile: null
    });
    setNgshopValues([]);
    setReceiveDateValues([]);
    setLimitDate({ min: null, max: null });
  }
  /* DB更新 */
  const handleProductRegistClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.plan_id) {
      window.alert('企画を選択してください。');
      return;
    }
    if (!data.product_code) {
      window.alert('商品コードを入力してください。');
      return;
    }
    if (!data.product_name) {
      window.alert('商品名を入力してください。');
      return;
    }
    if (!data.product_dept_code) {
      window.alert('商品デプトを入力してください。');
      return;
    }
    if (!data.price) {
      window.alert('金額(税込)を入力してください。');
      return;
    }
    if (!data.price_before_tax) {
      window.alert('金額(税別)を入力してください。');
      return;
    }
    if (!data.tax_rate) {
      window.alert('税率を入力してください。');
      return;
    }
    if(data.year_round_type==="1"){
      if (!data.receipt_limit_days) {
        window.alert('受取可能日数を入力してください。');
        return;
      }
      if (!data.change_limit_days) {
        window.alert('変更可能日数を入力してください。');
        return;
      }
      if(parseInt(data.receipt_limit_days)<parseInt(data.change_limit_days)) {
        if (!window.confirm(`受取可能日数と変更可能日数が逆転しています。登録してよろしいですか？`)) {
          return;
        }
      }
      data.changeLimit=new Date('9999/12/31');
      if (!data.orderLimit) data.orderLimit=new Date('9999/12/31');
    }else{
      if (!data.orderLimit) {
        window.alert('締め切り日を入力してください。');
        return;
      }
      if (!data.changeLimit) {
        window.alert('変更可能日を入力してください。');
        return;
      }
      if (!data.stock) {
        window.alert('在庫数を入力してください。');
        return;
      }
      data.receipt_limit_days=0;
      data.change_limit_days=0;
    }

    var params = data;
    params.order_limit = dateToStore(data.orderLimit);
    params.change_limit = dateToStore(data.changeLimit);
    params.early_discount_start = dateToStore(data.earlyDiscountStart);
    params.early_discount_end = dateToStore(data.earlyDiscountEnd);
    if (data.uploadfiletype === null) data.uploadfile = null;
    params.m_ng_shop = ngShop.map((a) => {
      return { product_id: params.product_id, shop_id: a.value };
    });
    params.m_product_receive = receiveDate.map((a) => {
      return { product_id: params.product_id, receive_date: a.value };
    });
    params.ins_name = user.userId;
    params.upd_name = user.userId;

    let res;
    try {
      setLockData(true);
      if (params.product_id === null || params.product_id === "") {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      } else {

        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/product/${params.product_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshProduct();
      }
      setLockData(false);
      handleProductResetClick();
    }
  }
  /* DB削除 */
  const handleProductDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`商品[${data.product_id}:${data.product_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      product_id: data.product_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/product/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshProduct();
      }
      setLockData(false);
      handleProductResetClick();
    }
  }

  /* */
  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleProductUpdateClick(data)}>
        編集
      </button>
    )
  }

  /* */
  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.plam_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleProductRegistClick(data)}>
        登録
      </button>
    )
  }

  /* */
  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.product_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleProductDeleteClick(data)}>
        削除
      </button>
    )
  }
  /* */
  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1`}
        onClick={() => handleProductResetClick()}>
        リセット
      </button>
    )
  }

  const handleChangeReviceDate = (data) => {
    setReceiveDateValues(data);
  }

  const handleChangeDate = (name, value) => {
    setValues({ ...values, [name]: value });
  }
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    var data={ ...values, [name]: value };
    if (name === "plan_id") {
      const data2 = planMaster.find(p => p.plan_id.toString() === value.toString());
      if (data2){
        data={...data,year_round_type:data2.year_round_type};
        setLimitDate({ ...limitDate, min: strToDate(data2.plan_start), max: strToDate(data2.plan_end) });
      } 
    }
    setValues(data);
  }
  const handleChangePlan = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setPlan({ ...plan, [name]: value });
  }

  const handleChangeProductCategory = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setPlan({ ...plan, [name]: value });
  }

  const handleChangeNgshop = (data) => {
    setNgshopValues(data);
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setValues({
        ...values,
        uploadfile: reader.result,
        uploadfiletype: value.type,
        deletefile: values.product_image
      });
    }
    reader.readAsDataURL(value);
  }
  const handleDeleteFile = () => {

    setValues({ ...values, uploadfile: null, uploadfiletype: null, deletefile: values.product_image });

  }

  useEffect(() => {
    refreshProduct();
  }, [plan, refreshProduct]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>企画</span>
          </div>
          <div className="col-4">
            <select className="custom-select w-100" aria-label="企画" name="plan_id" value={values.plan_id || ''} onChange={handleChange}>
              <option value="">選択してください</option>
              {planMaster.map((item) =>
                <option key={item.plan_id} value={item.plan_id}>{item.plan_name}({item.plan_start}～{item.plan_end})</option>
              )}
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>商品コード 商品名</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" name="product_code" value={values.product_code} onChange={handleChange} placeholder="商品コードを入力してください" />
          </div>
          <div className="col-4 text-center">
            <input type="text" className="form-control" name="product_name" value={values.product_name} onChange={handleChange} placeholder="商品名を入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <label htmlFor="product_category_id">商品カテゴリ</label>
          </div>
          <div className="col-4">
            <select className="custom-select w-100" aria-label="商品カテゴリ" name="product_category_id" value={values.product_category_id || ''} onChange={handleChange}>
              <option value="">選択してください</option>
              {productCategoryMaster.map((item) =>
                <option key={item.product_category_id} value={item.product_category_id}>{item.product_category_name}</option>
              )}
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <label htmlFor="product_category_id">商品デプト</label>
          </div>
          <div className="col-4">
            <select className="custom-select w-100" aria-label="商品デプト" name="product_dept_code" value={values.product_dept_code || ''} onChange={handleChange}>
              <option value="">選択してください</option>
              {productDeptMaster.map((item) =>
                <option key={item.product_dept_code} value={item.product_dept_code} >
                  {item.product_dept_name}
                </option>
              )}
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <label htmlFor="remarks">商品詳細</label>
          </div>
          <div className="col-5">
            <textarea className="form-control" rows="3" name="product_detail" id="product_detail" value={values.product_detail} onChange={handleChange}></textarea>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <label htmlFor="remarks">商品画像</label>
          </div>
          <div className="col-5">
            {!(values.uploadfile) && <input type="file" className="form-control-file" name="productImage" id="productImage" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
            {values.uploadfile && <object id="uploadfileview" data={values.uploadfile} type={values.uploadfiletype}><img src={values.uploadfile} alt='uploadfile' /></object>}
            {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>税込単価</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="price" name="price" value={values.price} onChange={handleChange} />
          </div>
          <div className="col-1 text-center">
            <span>税別単価</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="price_before_tax" name="price_before_tax" value={Math.floor(values.price_before_tax)} onChange={handleChange} />
          </div>
          <div className="col-1 text-center">
            <span>税率</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="tax_rate" name="tax_rate" value={values.tax_rate} onChange={handleChange} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>ポイント付与数</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="point" name="point" value={values.point} onChange={handleChange} />
          </div>
          <div className="col-1 text-center">
            <span>ポイント付与率</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="point_rate" name="point_rate" value={values.point_rate} onChange={handleChange} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <label htmlFor="planStart">締め切り日</label>
          </div>
          <div className="col-2">

            {<DatePicker minDate={limitDate.min} maxDate={limitDate.max} className="form-control w-100" aria_labal="締め切り日" locale="ja" dateFormat="yyyyMMdd" monthsShown="2" id="orderLimit" name="orderLimit" selected={values.orderLimit} onChange={date => handleChangeDate("orderLimit", date)} />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <label htmlFor="planEnd">{values.year_round_type==="1"?"変更可能日数":"変更可能日"}</label>
          </div>
          <div className="col-2">
            {values.year_round_type==="1"  && <input type="text" className="form-control w-100" name="change_limit_days" id="change_limit_days" value={values.change_limit_days} onChange={handleChange}/>}
            {values.year_round_type!=="1" && <DatePicker minDate={limitDate.min} maxDate={limitDate.max} className="form-control w-100" locale="ja" dateFormat="yyyyMMdd" monthsShown="2" name="changeLimit" id="changeLimit" selected={values.changeLimit} onChange={date => handleChangeDate("changeLimit", date)} />}
          </div>
          {values.year_round_type==="1"  &&  <div className="col-8">※通年のため、変更可能日数（受取日から●日前）の日数を指定してください。</div>}
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>早割割引率</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="early_discount_rate" name="early_discount_rate" value={values.early_discount_rate} onChange={handleChange} />
          </div>
          <div className="col-1 text-center">
            <label htmlFor="planStart">早割開始日</label>
          </div>
          <div className="col-2">
            {<DatePicker minDate={limitDate.min} maxDate={limitDate.max} className="form-control w-100" locale="ja" dateFormat="yyyyMMdd" monthsShown="2" name="earlyDiscountStart" id="earlyDiscountStart" selected={values.earlyDiscountStart} onChange={date => handleChangeDate("earlyDiscountStart", date)} />}
          </div>
          <div className="col-1">
            <label htmlFor="planEnd">早割終了日</label>
          </div>
          <div className="col-2">
            {<DatePicker minDate={limitDate.min} maxDate={limitDate.max} className="form-control w-100" locale="ja" dateFormat="yyyyMMdd" monthsShown="2" name="earlyDiscountEnd" id="earlyDiscountEnd" selected={values.earlyDiscountEnd} onChange={date => handleChangeDate("earlyDiscountEnd", date)} />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>在庫数</span>
          </div>
          <div className="col-2 text-center">
            <input type="text" className="form-control" id="stock" name="stock" value={values.stock} onChange={handleChange} />
          </div>
        </div>
        {values.year_round_type==="1"  && 
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>受取可能日数</span>
            </div>
            <div className="col-2 text-left">
              <input type="text" className="form-control w-100"x name="receipt_limit_days" id="receipt_limit_days" value={values.receipt_limit_days} onChange={handleChange}/>
            </div>
            <div className="col-8">※通年のため、受取可能日数（注文日から●日後）の日数を指定してください。</div>
          </div>
        }
        {values.year_round_type!=="1"  && 
          <div className="row mb-3">
            <div className="col-2 text-center">
              <span>受け取り可能日</span>
            </div>
            <div className="col-10"><CreatableSelect
              name="revice_date" id="revice_date"
              isMulti
              options={receiveDate}
              value={receiveDate}
              components={{ DropdownIndicator: null }}
              onChange={handleChangeReviceDate}
              placeholder="受け取り可能日を8桁で入力してください"
            /></div>
          </div>
        }
        <div className="row mb-3">
          <div className="col-2 text-center">
            <span>受け取り不可店舗</span>
          </div>
          <div className="col-10 text-left">
            <Select options={shopMaster} isMulti closeMenuOnSelect={false} value={ngShop} onChange={handleChangeNgshop} placeholder="受け取り不可店舗を選択してください" />
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderResetButton(values)}
          </div>
        </div>
      </div>
    );
  }
  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="row d-none"><div className="col-12">{util.inspect(productData)}</div></div>
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-${constClass.COLOR[user.userClass]}`}>
              <tr>
                <td className="text-center align-middle">
                  商品コード
                </td>
                <td className="text-center align-middle">
                  企画
                </td>
                <td className="text-center align-middle">
                  商品名
                </td>
                <td className="text-center align-middle">
                  商品カテゴリ
                </td>
                <td className="text-center align-middle">
                  商品デプト
                </td>
                <td className="text-center align-middle">
                  締め切り日
                </td>
                <td className="text-center align-middle">
                  変更可能日
                </td>
                <td className="text-center align-middle">
                  上限数 / 注文数
                </td>
                <td className="text-center align-middle">
                  税込み
                </td>
                <td className="text-center align-middle">
                  税別
                </td>
                <td className="text-center align-middle">
                  税率
                </td>
                <td className="text-center align-middle">
                  更新日
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {productData.map((data, idx) => (
                <tr key={data.product_id}>
                  {/* 商品ID */}
                  <td className="text-center align-middle">
                    {data.product_code}
                  </td>
                  {/* 企画 */}
                  <td className="text-center align-middle">
                    {data.plan_name}
                  </td>
                  {/* 商品名 */}
                  <td className="text-center align-middle">
                    {data.product_name}
                  </td>
                  {/* 商品カテゴリ名 */}
                  <td className="text-center align-middle">
                    {data.product_category_id}:{data.product_category_name}
                  </td>
                  {/* 商品デプト名 */}
                  <td className="text-center align-middle">
                    {data.product_dept_code}:{data.product_dept_name}
                  </td>
                  {/* 締め切り日 */}
                  <td className="text-center align-middle">
                    {data.order_limit}
                  </td>
                  {/* 変更可能日 */}
                  <td className="text-center align-middle">
                    {data.change_limit}
                  </td>
                  {/* 在庫数/注文数 */}
                  <td className="text-center align-middle">
                    {data.stock} / {data.order_count}
                  </td>
                  {/* 税込み */}
                  <td className="text-center align-middle">
                    {data.price.toLocaleString()}
                  </td>
                  {/* 税別 */}
                  <td className="text-center align-middle">
                    {Math.floor(data.price_before_tax).toLocaleString()}
                  </td>
                  {/* 税率 */}
                  <td className="text-center align-middle">
                    {data.tax}
                  </td>
                  {/* 更新日時 */}
                  <td className="text-center align-middle">
                    {data.upd_date ? moment(data.upd_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  
  const renderPreview = () => {
    return (
      <div className="modal" id="exampleModal">
        <div className="modal-dialog productpreview-modal">
          <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">
            {productData &&
              <ProductList
                products={productData}
                disabled={lockData}
                message={[]}
                receive_shop={null}
                displayCartCount={() => { return 0 }}
                addCart={() => { }}
                subCart={() => { }} />
            }
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row my-2 ">
        <div className="col-2 text-center line-height-2-2">
          <span className="d-inline-block align-middle">企画</span>
        </div>
        <div className="col-3 text-center line-height-2-2">
          <select className="custom-select w-100" aria-label="企画" name="plan_id" value={plan.plan_id || ''} onChange={handleChangePlan}>
            <option value="">- 全て -</option>
            {planMaster.map((item) =>
              <option key={item.plan_id} value={item.plan_id}>{item.plan_name}({item.plan_start}～{item.plan_end})</option>
            )}
          </select>
        </div>
        <div className="col-2 text-center line-height-2-2">
          <span className="d-inline-block align-middle">商品カテゴリ</span>
        </div>
        <div className="col-3 text-center line-height-2-2">
          <select className="custom-select w-100" aria-label="商品カテゴリ" name="product_category_id" value={plan.product_category_id || ''} onChange={handleChangeProductCategory}>
            <option value="">- 全て -</option>
            {productCategoryMaster.map((item) =>
              <option key={item.product_category_id} value={item.product_category_id}>{item.product_category_name}</option>
            )}
          </select>
        </div>
        <div className="col-2 text-left line-height-2-2">
          <button type="button"
            disabled={lockData}
            className={`btn btn-primary mx-1 `}
            data-toggle="modal" data-target="#exampleModal"
            onClick={() => renderPreview}>
            プレビュー表示
          </button>
        </div>
      </div>
      {productData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {productData !== null && (renderList())}
      {renderInput()}
      {renderPreview()}
    </div>
  )
}

export default Product;