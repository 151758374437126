import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CSVLink } from "react-csv";

const BackyardOrderList = (props) => {
  const { user } = props;
  const [orderData, setOrderData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [sumCount, setSumCount] = useState(0); //合計点数
  const [sumTotal, setSumTotal] = useState(0); //合計金額
  const [orderCount, setOrderCount] = useState(0); //注文件数
  const [sortOrder, setSortOrder] = useState([]); // ソート順指定
  const { addToast } = useToasts();
  const componentRef = useRef();
  const [csvData, setCsvData] = useState(null);
  const [csvDetailData, setCsvDetailData] = useState(null);

  const refreshOrder = useCallback(async () => {
    // console.log(props);
    var reg_params = {};
    reg_params = {
      "operator": "and",
      "where": []
    }
    // 店舗
    if (props.condition.user.userClass !== constClass.CLASS.ADMIN) {
      //店舗ユーザは自分の店舗のみ
      reg_params.where.push({ "receive_shop": props.condition.user.shopId });
    } else
      if (props.condition.shop !== "") {
        reg_params.where.push({ "receive_shop": props.condition.shop });
      }
    // 企画
    if (props.condition.plan !== "") {
      reg_params.where.push({ "plan_id": props.condition.plan });
    }
    // 受取日
    if (props.condition.reciveDate !== null) {
      reg_params.where.push({
        "operator": "in",
        "attr": "receive_date",
        "val": props.condition.reciveDate
      });
    }
    // 受取日
    if (props.condition.status !== null) {
      reg_params.where.push({
        "operator": "in",
        "attr": "status",
        "val": props.condition.status
      });
    }
    // 受取日(開始)
    if (props.condition.startDate) {
      reg_params.where.push({
        "operator": "gte",
        "attr": "receive_date",
        "val": props.condition.startDate
      });

      // 受取日(終了)
      if (props.condition.endDate) {
        reg_params.where.push({
          "operator": "lte",
          "attr": "receive_date",
          "val": props.condition.endDate
        });
      } else {
        reg_params.where.push({
          "operator": "lte",
          "attr": "receive_date",
          "val": props.condition.startDate
        });
      }
    }

    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/detail/`, reg_params)).data;

    var sc = 0;
    var st = 0;
    data.filter(d => d.status !== constClass.STATUS.CCL).forEach(d => {
      st += Math.floor(d.total);
      sc += d.t_order_detail.reduce((sum, element) => sum + element.count, 0);
    });
    setSumCount(sc);
    setSumTotal(st);
    setOrderCount(data.filter(d => d.status !== constClass.STATUS.CCL).length);
    data.sort((a, b) => (a.receive_date === b.receive_date) ? Number(a.receive_time) - Number(b.receive_time) : new Date(a.receive_date) - new Date(b.receive_date));
    setOrderData(data);
    createCsvdata(data);
    createCsvDetaildata(data);
  }, [props]);

  // const handleOrderClick = async (order_id) => {
  //   userActions.refresh();
  //   history.replace(`${props.match.path}order/input/${order_id}`);
  // }

  const handleStatusButtonClick = async (order_id, status) => {
    var order = orderData.find(o => o.order_id === order_id);
    if (order.status === status) {
      // 同じボタンを押したときは元に戻す
      status = constClass.STATUS.REG;
    }
    // console.log(util.inspect(order.t_order_detail));
    // 受け取り以外の操作は変更可能日を過ぎるとADMIN権限を持たないユーザは操作できない
    if (status !== constClass.STATUS.FIN && user.userClass !== constClass.CLASS.ADMIN && order.t_order_detail.reduce((min, detail) => min > detail.m_product.change_limit ? detail.m_product.change_limit : min, '99999999') < moment().format('YYYYMMDD')) {
      window.alert(`変更可能日を過ぎているため操作できません。本部へご連絡をお願いします。`);
      return;
    }
    if (status === constClass.STATUS.CCL && !window.confirm(`注文番号[${order.receipt_num}]をキャンセルします。よろしいですか？`)) {
      return;
    }
    if (status === constClass.STATUS.FIN && !window.confirm(`注文番号[${order.receipt_num}]を受取済にします。よろしいですか？`)) {
      return;
    }
    if (status === constClass.STATUS.REG && !window.confirm(`注文番号[${order.receipt_num}]のステータスを元に戻します。よろしいですか？`)) {
      return;
    }
    const params = {
      status,
      user_id: [constClass.STATUS.REG, constClass.STATUS.FIN, constClass.STATUS.CCL].includes(status) ? user.userId : null,
    };
    try {
      setLockData(true);
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${order_id}`, params);
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      addToast('ステータスを更新しました。', { appearance: 'success', autoDismiss: true });
      await refreshOrder();
      setLockData(false);
    }
  }

  const handleHeaderClick = (column) => {
    var _sortOrder = sortOrder;
    if (_sortOrder.map(s => s.column).includes(column)) { // 既に登録済み
      if (_sortOrder[0].column !== column) { // 先頭ではない
        _sortOrder.splice(_sortOrder.findIndex(s => s.column === column), 1); //削除
        _sortOrder.unshift({ column, order: 'ASC' }); // 追加
      } else { //先頭 並び順変更
        _sortOrder[0].order = _sortOrder[0].order === 'ASC' ? 'DESC' : 'ASC';
      }
    } else { //未登録
      _sortOrder.unshift({ column, order: 'ASC' }); // 追加
    }
    if (_sortOrder.length > 3) { //ソート順は3件まで
      _sortOrder.pop();
    }
    setSortOrder(_sortOrder);
    var data = orderData.slice();
    data.sort((a, b) => sortData(a, b));
    setOrderData(data);
  }

  const sortData = (a, b) => {
    if (sortOrder.length <= 0) { // 初期値は受取日→受取時間
      return (a.receive_date === b.receive_date) ? Number(a.receive_time) - Number(b.receive_time) : new Date(a.receive_date) - new Date(b.receive_date);
    }
    for (var i = 0; i < sortOrder.length; i++) {
      var item = sortOrder[i];
      if (item.column === 't_order_detail') {
        if (item.order === 'ASC') {
          if (a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) < b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return -1;
          if (a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) > b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return 1;
        } else {
          if (b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) < a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return -1;
          if (b.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0) > a.t_order_detail.reduce((sum, d) => sum + Number(d.count), 0)) return 1;
        }
      } else if (item.column === 'status') {
        const aStatus = a.status === constClass.STATUS.REG ? 1 : a.status === constClass.STATUS.PRE ? 2 : a.status === constClass.STATUS.FIN ? 3 : 4;
        const bStatus = b.status === constClass.STATUS.REG ? 1 : b.status === constClass.STATUS.PRE ? 2 : b.status === constClass.STATUS.FIN ? 3 : 4;
        if (item.order === 'ASC') {
          if (aStatus < bStatus) return -1;
          if (aStatus > bStatus) return 1;
        } else {
          if (bStatus < aStatus) return -1;
          if (bStatus > aStatus) return 1;
        }
      } else if (item.column === 'total' || item.column === 'receipt_num') {
        if (item.order === 'ASC') {
          if (Number(a[item.column]) < Number(b[item.column])) return -1;
          if (Number(a[item.column]) > Number(b[item.column])) return 1;
        } else {
          if (Number(b[item.column]) < Number(a[item.column])) return -1;
          if (Number(b[item.column]) > Number(a[item.column])) return 1;
        }
      } else if (item.column === 'employee_flag') {
        const aFlag = a.employee_flag === '1' ? 0 : 1;
        const bFlag = b.employee_flag === '1' ? 0 : 1;
        if (item.order === 'ASC') {
          if (aFlag < bFlag) return -1;
          if (aFlag > bFlag) return 1;
        } else {
          if (bFlag < aFlag) return -1;
          if (bFlag > aFlag) return 1;
        }
      } else {
        if (item.order === 'ASC') {
          if (a[item.column] < b[item.column]) return -1;
          if (a[item.column] > b[item.column]) return 1;
        } else {
          if (b[item.column] < a[item.column]) return -1;
          if (b[item.column] > a[item.column]) return 1;
        }
      }
    }
    return 0;
  }
  const createCsvdata = (data) => {
    var list = [];
    var head = [];
    head.push({ label: "注文番号", key: "receipt_num" });
    head.push({ label: "従業員", key: "employee_flag" });
    head.push({ label: "お客様名", key: "customer_name" });
    head.push({ label: "カナ", key: "customer_kana" });
    head.push({ label: "受取店舗ID", key: "receive_shop" });
    head.push({ label: "受取店舗", key: "receive_shop_name" });
    head.push({ label: "企画ID", key: "plan_id" });
    head.push({ label: "企画名", key: "plan_name" });
    head.push({ label: "受取日", key: "receive_date" });
    head.push({ label: "受取時間帯", key: "receive_time" });
    head.push({ label: "種別", key: "order_type" });
    head.push({ label: "小計", key: "subtotal" });
    head.push({ label: "割引額", key: "discount" });
    head.push({ label: "税額", key: "tax" });
    head.push({ label: "税込総額", key: "total" });
    head.push({ label: "受注者", key: "contractor" });
    head.push({ label: "入力者", key: "inputuser" });
    head.push({ label: "注文日", key: "ins_date" });
    head.push({ label: "更新日", key: "upd_date" });
    head.push({ label: "ステータス", key: "status" });
    data.forEach(p => {
      var product = {
        receipt_num: p.receipt_num,
        employee_flag: p.employee_flag === '1' ? '○' : '',
        customer_name: p.customer_name,
        customer_kana: p.customer_kana,
        receive_shop: p.receive_shop,
        receive_shop_name: p.receive_shop_name,
        plan_id: p.plan_id,
        plan_name: p.plan_name,
        receive_date: p.receive_date,
        receive_time: constClass.RECEIVE_TIME_NAME[p.receive_time],
        order_type: p.order_type,
        subtotal: Math.floor(p.subtotal).toLocaleString(),
        discount: Math.floor(p.discount).toLocaleString(),
        tax: Math.floor(p.tax).toLocaleString(),
        total: Math.floor(p.total).toLocaleString(),
        contractor: p.contractor,
        inputuser: p.inputuser,
        ins_date: moment(p.ins_date).format("YYYY/MM/DD HH:mm:ss"),
        upd_date: moment(p.upd_date).format("YYYY/MM/DD HH:mm:ss"),
        status: constClass.STATUS_NAME[p.status],
      };
      // p.t_order_detail.map(d => {
      //   product = { ...product, product_id: d.product_id }
      //   product = { ...product, product_name: d.product_name }
      //   product = { ...product, early_discount: d.early_discount === constClass.EARLY_DISCOUNT.ON ? "対象" : "対象外" }
      //   product = { ...product, price_before_tax: d.price_before_tax }
      //   product = { ...product, price: d.price }
      //   product = { ...product, tax_rate: d.tax_rate }
      //   product = { ...product, count: d.count }
      //   list.push(product);
      // });
      list.push(product);
    });

    setCsvData({ header: head, list: list });
  }

  const createCsvDetaildata = (data) => {
    var list = [];
    var head = [];
    head.push({ label: "注文番号", key: "receipt_num" });
    head.push({ label: "従業員", key: "employee_flag" });
    head.push({ label: "お客様名", key: "customer_name" });
    head.push({ label: "カナ", key: "customer_kana" });
    head.push({ label: "受取店舗ID", key: "receive_shop" });
    head.push({ label: "受取店舗", key: "receive_shop_name" });
    head.push({ label: "企画ID", key: "plan_id" });
    head.push({ label: "企画名", key: "plan_name" });
    head.push({ label: "受取日", key: "receive_date" });
    head.push({ label: "受取時間帯", key: "receive_time" });
    head.push({ label: "種別", key: "order_type" });
    head.push({ label: "小計", key: "subtotal" });
    head.push({ label: "割引額", key: "discount" });
    head.push({ label: "税額", key: "tax" });
    head.push({ label: "税込総額", key: "total" });
    head.push({ label: "商品CD", key: "product_code" });
    head.push({ label: "商品名", key: "product_name" });
    head.push({ label: "商品単価", key: "price" });
    head.push({ label: "商品税率", key: "tax_rate" });
    head.push({ label: "商品早割割引対象", key: "early_discount" });
    head.push({ label: "商品注文数", key: "count" });
    head.push({ label: "受注者", key: "contractor" });
    head.push({ label: "入力者", key: "inputuser" });
    head.push({ label: "注文日", key: "ins_date" });
    head.push({ label: "更新日", key: "upd_date" });
    head.push({ label: "ステータス", key: "status" });
    data.forEach(p => {
      var product = {
        receipt_num: p.receipt_num,
        employee_flag: p.employee_flag === '1' ? '○' : '',
        customer_name: p.customer_name,
        customer_kana: p.customer_kana,
        receive_shop: p.receive_shop,
        receive_shop_name: p.receive_shop_name,
        plan_id: p.plan_id,
        plan_name: p.plan_name,
        receive_date: p.receive_date,
        receive_time: constClass.RECEIVE_TIME_NAME[p.receive_time],
        order_type: p.order_type,
        subtotal: Math.floor(p.subtotal).toLocaleString(),
        discount: Math.floor(p.discount).toLocaleString(),
        tax: Math.floor(p.tax).toLocaleString(),
        total: Math.floor(p.total).toLocaleString(),
        product_code: null,
        product_name: null,
        early_discount: null,
        price_before_tax: null,
        price: null,
        tax_rate: null,
        count: 0,
        contractor: p.contractor,
        inputuser: p.inputuser,
        ins_date: moment(p.ins_date).format("YYYY/MM/DD HH:mm:ss"),
        upd_date: moment(p.upd_date).format("YYYY/MM/DD HH:mm:ss"),
        status: constClass.STATUS_NAME[p.status],
      };
      p.t_order_detail.forEach(d => {
        product = { ...product, product_code: d.product_code }
        product = { ...product, product_name: d.product_name }
        product = { ...product, early_discount: d.early_discount === constClass.EARLY_DISCOUNT.ON ? "対象" : "対象外" }
        product = { ...product, price_before_tax: d.price_before_tax }
        product = { ...product, price: d.price }
        product = { ...product, tax_rate: d.tax_rate }
        product = { ...product, count: d.count }
        list.push(product);
      });
    });

    setCsvDetailData({ header: head, list: list });
  }
  const renderButton = (data, status, statusName, additionalStatus = []) => {
    additionalStatus.push(status);
    statusName = [constClass.STATUS_NAME.FIN].includes(statusName) && !additionalStatus.includes(data.status) ? '受取' : statusName;
    return (
      <button type="button"
        disabled={lockData}
        className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-primary' : 'btn-secondary')} mx-1`}
        onClick={() => handleStatusButtonClick(data.order_id, status)}>
        {statusName}
      </button>
    )
  }

  const renderSortTh = (column) =>
    <th className="text-center align-middle like-button p-0 m-0" onClick={() => handleHeaderClick(column)}>
      {sortOrder.filter(s => s.column === column).map((item, idx, self) =>
        <span>{item.order === 'ASC' ? '▲' : '▼'}{sortOrder.findIndex(s => s.column === column) + 1}</span>
      )}
    </th>

  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {orderData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {orderData !== null && (
        <div ref={componentRef} className="print-list">
          <div className="row mb-0 p-0 ">
            <div className="col-9 pl-0 text-left align-self-end">
              {props.condition.shop && orderData[0] && <div className="row"><div className="col-12"><h4>{orderData[0].receive_shop_name}</h4></div></div>}
              <div className="row">
                <div className="col-2 pr-2">
                  {orderData[0] &&
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="btn btn-primary print-none px-0 mb-1 w-100">
                          注文情報印刷
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle={""}
                    />}
                </div>
                <div className="col-2 px-2">
                  {csvData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvData.list} headers={csvData.header} filename={"注文データ.csv"}>
                      注文データ出力
                    </CSVLink>
                  }
                </div>
                <div className="col-3 pl-2">
                  {csvDetailData &&
                    <CSVLink className="btn btn-primary print-none mb-1 px-0 w-100" data={csvDetailData.list} headers={csvDetailData.header} filename={"注文明細データ.csv"}>
                      注文明細データ出力
                    </CSVLink>
                  }
                </div>
              </div>
            </div>
            <div className="col-3 m-0 p-0">
              <table className="table-borderd table-striped mt-0 mr-0 mb-0 ml-auto p-0">
                <tbody>
                  <tr>
                    <th className="p-0">注文件数</th>
                    <td className="text-right p-0">{orderCount.toLocaleString()} 件</td>
                  </tr>
                  <tr>
                    <th className="p-0">1人当たりの注文点数</th>
                    <td className="text-right p-0">{(Math.round((sumCount / orderCount) * Math.pow(10, 1)) / Math.pow(10, 1)).toLocaleString()} 個</td>
                  </tr>
                  <tr>
                    <th className="p-0">1人当たりの注文単価</th>
                    <td className="text-right p-0">{Math.round((sumTotal / orderCount)).toLocaleString()} 円</td>
                  </tr>
                  <tr>
                    <th className="p-0">合計点数</th>
                    <td className="text-right p-0">{sumCount.toLocaleString()} 個</td>
                  </tr>
                  <tr>
                    <th className="p-0">税込合計金額</th>
                    <td className="text-right p-0">{sumTotal.toLocaleString()} 円</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mb-3 p-0">
            <div className="col-12 p-0">
              <table className="table table-bordered table-striped" height="1">
                <thead className={`table-${constClass.COLOR[user.userClass]}`}>
                  <tr>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('receipt_num')}>
                      注文<br />番号
                    </th>
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('employee_flag')}>
                      従<br />業<br />員
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('customer_name')}>
                      お客様名
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('customer_kana')}>
                      カナ
                    </th>
                    {!props.condition.shop && (
                      <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('receive_shop')}>
                        受取<br />店舗ID
                      </th>
                    )}
                    {!props.condition.shop && (
                      <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('receive_shop')}>
                        受取店舗
                      </th>
                    )}
                    <th className="text-center align-middle text-nowrap like-button" onClick={() => handleHeaderClick('plan_id')}>
                      企画
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('receive_date')}>
                      受取日
                    </th>
                    <th className="text-center text-nowrap align-middle like-button" onClick={() => handleHeaderClick('receive_time')}>
                      受取時間帯
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('order_type')}>
                      種別
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('total')}>
                      税込総額
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('t_order_detail')}>
                      注文明細
                    </th>
                    <th className="text-center align-middle like-button" onClick={() => handleHeaderClick('status')}>
                      ステータス
                    </th>
                    <th className="text-center align-middle print-none">
                      処理
                    </th>
                  </tr>
                  <tr className="print-none">
                    {renderSortTh('receipt_num')}
                    {renderSortTh('employee_flag')}
                    {renderSortTh('customer_name')}
                    {renderSortTh('customer_kana')}
                    {!props.condition.shop && (renderSortTh('receive_shop'))}
                    {!props.condition.shop && (renderSortTh('receive_shop'))}
                    {renderSortTh('plan_id')}
                    {renderSortTh('receive_date')}
                    {renderSortTh('receive_time')}
                    {renderSortTh('order_type')}
                    {renderSortTh('total')}
                    {renderSortTh('t_order_detail')}
                    {renderSortTh('status')}
                    <th className="text-center align-middle print-none">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.map((data, idx) => (
                    <tr key={data.order_id}>
                      {/* 注文番号 */}
                      <td className="text-center align-middle p-1">
                        {data.receipt_num}
                      </td>
                      {/* 従業員 */}
                      <td className="text-center align-middle p-1">
                        {data.employee_flag === '1' ? '○' : ''}
                      </td>
                      {/* お客様名 */}
                      <td className="text-center align-middle p-1">
                        <Link to={`${props.match.path}order/input/${data.order_id}`}>{data.customer_name}</Link>
                      </td>
                      {/* カナ */}
                      <td className="text-center align-middle p-1">
                        {data.customer_kana}<br />
                      </td>
                      {/* 受取店舗ID */}
                      {!props.condition.shop && (
                        <td className="text-center align-middle p-1">
                          {data.receive_shop}
                        </td>
                      )}
                      {/* 受取店舗 */}
                      {!props.condition.shop && (
                        <td className="text-center align-middle p-1">
                          {data.receive_shop_name}
                        </td>
                      )}
                      {/* 企画 */}
                      <td className="text-center align-middle p-1 text-nowrap">
                        {data.plan_name}
                      </td>
                      {/* 受取日 */}
                      <td className="text-center align-middle p-1 text-nowrap">
                        {data.receive_date}
                      </td>
                      {/* 受取時間帯 */}
                      <td className="text-center align-middle p-1 text-nowrap">
                        {constClass.RECEIVE_TIME_NAME[data.receive_time]}
                      </td>
                      {/* 種別 */}
                      <td className="text-center align-middle p-1">
                        {data.order_type}
                      </td>
                      {/* 税込総額 */}
                      <td className="text-right align-middle p-1">
                        {Math.floor(data.total).toLocaleString()}
                      </td>
                      {/* 注文明細 */}
                      <td className="text-center align-middle p-0">
                        {data.t_order_detail.sort((a, b) => a.product_id - b.product_id).map((detail, detail_idx) => (
                          <div className="row p-0 m-1" key={data.order_id + '_' + detail.product_id}>
                          <div className="col-auto text-left align-self-center py-0 pl-0 pr-1 m-0">{detail.product_code}</div>
                            <div className="col-auto text-left align-self-center py-0 pl-0 pr-1 m-0">({detail.m_product.m_product_dept.product_d_name})</div>
                            <div className="col text-left align-self-center  p-0 m-0">{detail.product_name}</div>
                            <div className="col-auto  text-right align-self-center py-0 pl-1 pr-0 m-0">{detail.count}</div>
                          </div>
                        ))
                        }
                      </td>

                      {/* ステータス */}
                      <td className="text-center align-middle p-1">
                        {constClass.STATUS_NAME[data.status]}
                      </td>
                      {/* 処理 */}
                      <td className="text-center align-middle text-nowrap p-1 print-none">
                        {renderButton(data, constClass.STATUS.FIN, constClass.STATUS_NAME.FIN)}
                        {renderButton(data, constClass.STATUS.CCL, constClass.STATUS_NAME.CCL)}
                      </td>
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardOrderList;